@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
.app,
#root {
  height: 100%;
  background-color: #f8f9ff;
  color: #1d2026;
  @apply text-white;
  font-family: sans-serif;
}

nav a.active {
  color: #1d34ab;
}

nav a:hover {
  color: #1d34ab;
}

.photo {
  height: 250px;
  object-fit: cover;
  object-position: 0px 28%;
}

.photo-1 {
  height: 250px;
  object-fit: cover;
  object-position: 0px 12%;
}

.photo-2 {
  height: 250px;
  object-fit: contain;
}

.highlight {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffdb58 50%);
}

.highlight-2 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #c1fd33 50%);
}
